import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UiComponentsModule } from '@finxone-platform/ui-components';
import player from 'lottie-web/build/player/lottie_svg';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { DragScrollModule } from 'ngx-drag-scroll';
import { provideLottieOptions } from 'ngx-lottie';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CachedSrcDirective } from '../../lib/directives/cached.src.directive';
import { SafeHtmlPipe } from '../../lib/pipes/SafeHtml.pipe';

import { AccountBalanceWidgetComponent } from './account-balance-widget/account-balance-widget.component';
import { CardManagementWidgetComponent } from './card-management-widget/card-management-widget.component';
import { ChartComponent } from './chart/chart.component';
import { RecentTransactionHistoryWidgetComponent } from './recent-transaction-history-widget/recent-transaction-history-widget.component';
import { UserPropertiesTableComponent } from './user-management-widgets/user-properties-table/user-properties-table.component';
import { AddressComponent } from './widgets/address/address.component';
import { BirthdayComponent } from './widgets/birthday/birthday.component';
import { ButtonWidgetComponent } from './widgets/button-widget/button-widget.component';
import { ChangePinBottomPopupComponent } from './widgets/card-management-module/bottom-popup-content/change-pin-bottom-popup/change-pin-bottom-popup.component';
import { LostStolenBottomPopupComponent } from './widgets/card-management-module/bottom-popup-content/lost-stolen-bottom-popup/lost-stolen-bottom-popup.component';
import { ReplaceCardBottomPopupComponent } from './widgets/card-management-module/bottom-popup-content/replace-card-bottom-popup/replace-card-bottom-popup.component';
import { CardActivationComponent } from './widgets/card-management-module/widgets/card-activation/card-activation.component';
import { ChangePinWidgetComponent } from './widgets/card-management-module/widgets/change-pin-widget/change-pin-widget.component';
import { FreezeCardWidgetComponent } from './widgets/card-management-module/widgets/freeze-card-widget/freeze-card-widget.component';
import { LostStolenWidgetComponent } from './widgets/card-management-module/widgets/lost-stolen-widget/lost-stolen-widget.component';
import { ReplaceCardWidgetComponent } from './widgets/card-management-module/widgets/replace-card-widget/replace-card-widget.component';
import { TrackCardDeliveryStatusWidgetComponent } from './widgets/card-management-module/widgets/track-card-delivery-status-widget/track-card-delivery-status-widget.component';
import { CarouselWidgetComponent } from './widgets/carousel-widget/carousel-widget.component';
import { CompanyHouseCheckComponent } from './widgets/company-house-check/company-house-check.component';
import { CustomFieldComponent } from './widgets/custom-field/custom-field.component';
import { EmailComponent } from './widgets/email/email.component';
import { H1WidgetComponent } from './widgets/h1-widget/h1-widget.component';
import { IFrameWidgetComponent } from './widgets/iframe-widget/iframe-widget.component';
import { ImageWidgetComponent } from './widgets/image-widget/image-widget.component';
import { LineWidgetComponent } from './widgets/line-widget/line-widget.component';
import { MotVerificationComponent } from './widgets/mot-verification/mot-verification.component';
import { NameComponent } from './widgets/name/name.component';
import { OnboardingBackPopupComponent } from './widgets/onboarding-back-popup/onboarding-back-popup.component';
import { OnboardingErrorPopupComponent } from './widgets/onboarding-error-popup/onboarding-error-popup.component';
import { PasswordStrengthComponent } from './widgets/password-strength/password-strength.component';
import { PasswordComponent } from './widgets/password/password.component';
import { PhoneNumberComponent } from './widgets/phone-number/phone-number.component';
import { QuestionWidgetComponent } from './widgets/question-widget/question-widget.component';
import { QuickLinksWidgetComponent } from './widgets/quick-links-widget/quick-links-widget.component';
import { TextItemWidgetComponent } from './widgets/text-item-widget/text-item-widget.component';
import { TextWidgetComponent } from './widgets/text-widget/text-widget.component';
import { VerificationPopupComponent } from './widgets/verification-popup/verification-popup.component';

import { ExchangeQuoteSummaryComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/exchange-quote-widget/exchange-quote-summary/exchange-quote-summary.component';
import { ExchangeQuoteWidgetComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/exchange-quote-widget/exchange-quote-widget.component';
import { PaymentRefWidgetComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/payment-ref-widget/payment-ref-widget.component';
import { NotificationListComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/profile-widgets/notification-list-widget/notification-list.component';
import { NgxJdenticonModule } from 'ngx-jdenticon';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { LayoutsModule } from '../layouts/layouts.module';
import { AddCardBottomPopupComponent } from './bottom-popup/add-card-bottom-popup/add-card-bottom-popup.component';
import { IFCardComponent } from './components-library/IF-card/IF-card.component';
import { AccountsListingComponent } from './components-library/accounts-listing/accounts-listing.component';
import { ActivateCardStripComponent } from './components-library/activate-card-strip/activate-card-strip.component';
import { AdapterCardComponent } from './components-library/adapter-card-component/adapter-card.component';
import { AddNewCardComponent } from './components-library/add-new-card/add-new-card.component';
import { AlertHandlerComponent } from './components-library/alert-handler/alert-handler.component';
import { AmountSummaryComponent } from './components-library/amount-summary/amount-summary.component';
import { BalanceChartComponent } from './components-library/balance-chart/balance-chart.component';
import { BeneficiaryCarouselCardComponent } from './components-library/beneficiary-carousel-card/beneficiary-carousel-card.component';
import { BeneficiaryCarouselComponent } from './components-library/beneficiary-carousel/beneficiary-carousel.component';
import { BeneficiarySelectionComponent } from './components-library/beneficiary-selection/beneficiary-selection.component';
import { CardsCarouselComponent } from './components-library/cards-carousel/cards-carousel.component';
import { ChangePinComponent } from './components-library/change-pin/change-pin.component';
import { ConfirmationDialogComponent } from './components-library/confirmation-dilog/confirmation-dialog.component';
import { ConfirmationPopupComponent } from './components-library/confirmation-popup/confirmation-popup.component';
import { CreditDebitAmountSummaryComponent } from './components-library/credit-debit-amount-summary/credit-debit-amount-summary.component';
import { CurrencyListingComponent } from './components-library/currency-listing/currency-listing.component';
import { FinxCircleButtonComponent } from './components-library/finx-circle-button/finx-circle-button.component';
import { FreezeCardComponent } from './components-library/freeze-card/freeze-card.component';
import { FrozenStripComponent } from './components-library/frozen-strip/frozen-strip.component';
import { LogoutComponent } from './components-library/logout/logout.component';
import { LostStolenComponent } from './components-library/lost-stolen/lost-stolen.component';
import { LottieAnimationComponent } from './components-library/lottie-animation/lottie-animation.component';
import { MeaWalletCardComponent } from './components-library/mea-wallet-card/meawallet-card.component';
import { MenuWidgetComponent } from './components-library/menu-widget/menu-widget.component';
import { MonthlyBalanceComponent } from './components-library/monthly-balance/monthly-balance.component';
import { MonthlyExpenditureComponent } from './components-library/monthly-expenditure/monthly-expenditure.component';
import { MonthlyIncomeComponent } from './components-library/monthly-income/monthly-income.component';
import { MultiIconQuickLinkComponent } from './components-library/multi-icon-quick-link/multi-icon-quick-link.component';
import { OnboardingFormElementComponent } from './components-library/onboarding-form-element/onboarding-form-element.component';
import { OrderStatusComponent } from './components-library/order-status/order-status.component';
import { PaymentButtonComponent } from './components-library/payment-button/payment-button.component';
import { PopupSelectorComponent } from './components-library/popup-selector/popup-selector.component';
import { QuickLinksComponent } from './components-library/quick-links/quick-links.component';
import { ReplaceCardComponent } from './components-library/replace-card/replace-card.component';
import { SimpleBalanceComponent } from './components-library/simple-balance/simple-balance.component';
import { SwitchNavigatorQuickLinkComponent } from './components-library/switch-navigator-quick-link/switch-navigator-quick-link.component';
import { ToggleSwitchComponent } from './components-library/toggle-switch/toggle-switch.component';
import { TopPageNavigatorComponent } from './components-library/top-page-navigator/top-page-navigator.component';
import { TransactionHistoryComponent } from './components-library/transaction-history/transaction-history.component';
import { ViewPinComponent } from './components-library/view-pin/view-pin.component';
import { VirtualCardComponent } from './components-library/virtual-card/virtual-card.component';
import { DesktopTripListingComponent } from './desktop-trip-listing/desktop-trip-listing.component';
import { TripInformationComponent } from './trip-information/trip-information.component';
import { TripListingComponent } from './trip-listing/trip-listing.component';
import { AccountListComponent } from './widgets/account-list/account-list.component';
import { AccountSelectionComponent } from './widgets/account-selection/account-selection.component';
import { AccountsListingWidgetComponent } from './widgets/accounts-listing-widget/accounts-listing-widget.component';
import { AddBeneficiaryUkfpComponent } from './widgets/add-beneficiary/add-beneficiary-ukfp/add-beneficiary-ukfp.component';
import { AddBeneficiaryComponent } from './widgets/add-beneficiary/add-beneficiary.component';
import { AddCurrencyAccountWidgetComponent } from './widgets/add-currency-account-widget/add-currency-account-widget.component';
import { BeneficiaryCarouselWidgetComponent } from './widgets/beneficiary-carousel-widget/beneficiary-carousel-widget.component';
import { BeneficiarySelectionWidgetComponent } from './widgets/beneficiary-selection-widget/beneficiary-selection-widget.component';
import { ActivateCardSectionComponent } from './widgets/card-management-module/sections/activate-card-section/activate-card-section.component';
import { VirtualCardOptionsComponent } from './widgets/card-management-module/widgets/virtual-card-options/virtual-card-options.component';
import { ConfirmBeneficiaryDetailsComponent } from './widgets/confirm-beneficiary-details/confirm-beneficiary-details.component';
import { CurrencyInputFieldWidgetComponent } from './widgets/currency-input-field-widget/currency-input-field-widget.component';
import { EmailVerificationComponent } from './widgets/email-verification/email-verification.component';
import { ExchangeCurrencyWidgetComponent } from './widgets/exchange-currency-widget/exchange-currency-widget.component';
import { ImportUserComponent } from './widgets/import-user/import-user.component';
import { IntlBeneficiaryAddressComponent } from './widgets/intl-beneficiary-address/intl-beneficiary-address.component';
import { KycProviderComponent } from './widgets/kyc-provider/kyc-provider.component';
import { LabelInputComponent } from './widgets/label-input/label-input.component';
import { LogoutWidgetComponent } from './widgets/logout-widget/logout-widget.component';
import { LottieAnimationWidgetComponent } from './widgets/lottie-animation-widget/lottie-animation-widget.component';
import { MandatoryCTAButtonWidgetComponent } from './widgets/mandatory-cta-button-widget/mandatory-cta-button-widget.component';
import { MultiIconQuickLinkWidgetComponent } from './widgets/multi-icon-quick-link-widget/multi-icon-quick-link-widget.component';
import { MultiRoleWidgetComponent } from './widgets/multi-role-widget/multi-role-widget.component';
import { BackButtonComponent } from './widgets/navigation/back-widget/back-widget.component';
import { ForwardButtonComponent } from './widgets/navigation/forward-widget/forward-widget.component';
import { NotificationWidgetComponent } from './widgets/notification-widget/notification-widget.component';
import { PasswordSettingComponent } from './widgets/password-setting/password-setting.component';
import { BiometricsAuthenticationWidgetComponent } from './widgets/profile-widgets/biometrics-authentication-widget/biometrics-authentication-widget.component';
import { ProfilePictureWidgetComponent } from './widgets/profile-widgets/profile-picture-widget/profile-picture-widget.component';
import { ProgressbarWidgetComponent } from './widgets/progress-bar-widget/progress-bar-widget.component';
import { SimpleBalanceWidgetComponent } from './widgets/simple-balance-widget/simple-balance-widget.component';
import { SumsubWidgetComponent } from './widgets/sumsub-widget/sumsub-widget.component';
import { SwitchNavigatorQuickLinkWidgetComponent } from './widgets/switch-navigator-quick-link-widget/switch-navigator-quick-link-widget.component';
import { UserAccountDetailComponent } from './widgets/user-account-detail/user-account-detail.component';
import { UserAddressComponent } from './widgets/user-address/user-address.component';
import { UserContactDetailComponent } from './widgets/user-contact-detail/user-contact-detail.component';
import { UserDetailComponent } from './widgets/user-detail/user-detail.component';
import { VerificationWidgetComponent } from './widgets/verification-widget/verification-widget.component';

import { OverlayModule, ViewportRuler } from '@angular/cdk/overlay';
import { IntlBeneficiaryAddedComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/intl-payments/intl-beneficiary-added/intl-beneficiary-added.component';
import { AddFacilityWidgetComponent } from './add-facility-step1-widget/add-facility-step1-widget.component';
import { InternationalCurrencyCountryComponent } from './components-library/international-currency-country/international-currency-country.component';
import { OtpVerificationComponent } from './components-library/otp-verification/otp-verification.component';
import { LinkFacilityWidgetComponent } from './link-facility-widget/link-facility-widget.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { CompanyNameWidgetComponent } from './widgets/company-name-widget/company-name-widget.component';
import { CtaButtonWidgetComponent } from './widgets/cta-buttons/cta-button-widget/cta-button-widget.component';
import { EditAddressComponent } from './widgets/edit-address/edit-address.component';

import { DownloadWidgetComponent } from '@app/finxone-web-frontend/app/Modules/shared/widgets/profile-widgets/download-document-widget/download-document-widget.component';
import { CustomCurrencyPipe } from '@app/finxone-web-frontend/app/lib/pipes/custom-currency.pipe';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import {
  HlmCardContentDirective,
  HlmCardDescriptionDirective,
  HlmCardDirective,
  HlmCardFooterDirective,
  HlmCardHeaderDirective,
  HlmCardTitleDirective,
} from '@spartan-ng/ui-card-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { HlmLabelDirective } from '@spartan-ng/ui-label-helm';
import {
  HlmTabsComponent,
  HlmTabsContentDirective,
  HlmTabsListComponent,
  HlmTabsTriggerDirective,
} from '@spartan-ng/ui-tabs-helm';
import { NgOtpInputModule } from 'ng-otp-input';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { CustomViewportRuler } from '../../lib/cdk/custom-viewport-ruler';
import { DynamicStyleDirective } from '../../lib/directives/dynamic-style.directive';
import { LazyLoadDirective } from '../../lib/directives/lazy-load.directive';
import { FirstLetterPipe } from '../../lib/pipes/first-letter.pipe';
import { CardNameWidgetComponent } from './card-name-widget/card-name-widget.component';
import { AddEditFeeComponent } from './components-library/add-edit-fee/add-edit-fee.component';
import { AddFeeGroupComponent } from './components-library/add-fee-group/add-fee-group.component';
import { AssignGroupComponent } from './components-library/assign-group/assign-group.component';
import { AssignPersonaRoleWidgetComponent } from './components-library/assign-persona-role/assign-persona-role-widget.component';
import { BaseOtpVerificationComponent } from './components-library/base-otp-verification/base-otp-verification.component';
import { CardComponent } from './components-library/card/card.component';
import { CopyShareComponent } from './components-library/copy-share/copy-share.component';
import { CurrencyFeeConfigurationComponent } from './components-library/currency-fee-configuration/currency-fee-configuration.component';
import { FinxFormActionButtonComponent } from './components-library/finx-form-button/finx-form-action-button.component';
import { AppPinConfirmationComponent } from './confirmation-code/app-pin-confirmation/app-pin-confirmation.component';
import { PinBoxesComponent } from './confirmation-code/pin-boxes/pin-boxes.component';
import { PinPadComponent } from './confirmation-code/pin-pad/pin-pad.component';
import { ConfirmationWidgetComponent } from './confirmation-widget/confirmation-widget.component';
import { DesktopEntityListingComponent } from './desktop-entity-listing/desktop-entity-listing.component';
import { DesktopListingComponent } from './desktop-listing/desktop-listing.component';
import { DesktopManageUserListingComponent } from './desktop-manage-user-listing/desktop-manage-user-listing.component';
import { DesktopUserListingComponent } from './desktop-user-listing/desktop-user-listing.component';
import { ImageDropdownComponent } from './image-dropdown/image-dropdown.component';
import { DesktopBulkPaymentListingComponent } from './payments/desktop-guest-user-listing/desktop-bulk-payment-listing.component';
import { SectionWidgetComponent } from './section-widget/section-widget.component';
import { SpacerWidgetComponent } from './spacer-widget/spacer-widget';
import { TabWidgetComponent } from './tab-widget/tab-widget.component';
import { DesktopEvenLinkedListingComponent } from './travel-mangement-widgets/broker-employee-zone/desktop-event-linked-listing/desktop-event-linked-listing.component';
import { LinkTripWidgetComponent } from './travel-mangement-widgets/broker-employee-zone/link-trip-widget/link-trip-widget.component';
import { GuestDetailsTextWidgetComponent } from './travel-mangement-widgets/broker-guest-zone/guest-details-text-widget/guest-details-text-widget.component';
import { DesktopEmployeeUserListingComponent } from './travel-mangement-widgets/desktop-employee-user-listing/desktop-employee-user-listing.component';
import { DesktopGuestUserListingComponent } from './travel-mangement-widgets/desktop-guest-user-listing/desktop-guest-user-listing.component';
import { TripSelectorComponent } from './trip-selector/trip-selector.component';
import { TwoColumnWidgetComponent } from './two-column-widget/two-column-widget.component';
import { UploadFileWithPopupComponent } from './upload-file-with-popup/upload-file-with-popup.component';
import { AccountsContextDropdownWidgetComponent } from './widgets/accounts-context-dropdown-widget/accounts-context-dropdown-widget.component';
import { AccountsContextDropdownWidgetDirective } from './widgets/accounts-context-dropdown-widget/directives/accounts-context-dropdown-widget.directive';
import { SecondaryIdentifierComponent } from './widgets/add-beneficiary/secondary-identifier/secondary-identifier.component';
import { AddEditFeeWidgetComponent } from './widgets/add-edit-fee-widget/add-edit-fee-widget.component';
import { AddEmployeeStep1WidgetsComponent } from './widgets/add-employee-step-1-widgets/add-employee-step-1-widgets.component';
import { AddEmployeeStep2WidgetsComponent } from './widgets/add-employee-step-2-widgets/add-employee-step-2-widgets.component';
import { AddFeeGroupWidgetComponent } from './widgets/add-fee-group-widget/add-fee-group-widget.component';
import { AddToGooglePayWidgetComponent } from './widgets/add-to-google-pay-widget/add-to-google-pay-widget.component';
import { AssignGroupWidgetComponent } from './widgets/assign-group-widget/assign-group-widget.component';
import { EditBeneficiaryInternationalBeneficiaryCredentialsWidgetComponent } from './widgets/beneficiary-zone-widgets/edit-intl-beneficiary/edit-beneficiary-international-beneficiary-credentials-widget/edit-beneficiary-international-beneficiary-credentials-widget.component';
import { EditBeneficiaryInternationalCurrencyCountryWidgetComponent } from './widgets/beneficiary-zone-widgets/edit-intl-beneficiary/edit-beneficiary-international-currency-country-widget/edit-beneficiary-international-currency-country-widget.component';
import { EditBeneficiaryIntlBeneficiaryAddressComponent } from './widgets/beneficiary-zone-widgets/edit-intl-beneficiary/edit-beneficiary-intl-beneficiary-address/edit-beneficiary-intl-beneficiary-address.component';
import { BulkPaymentAccountsListingComponent } from './widgets/bulk-payment-accounts-listing/bulk-payment-accounts-listing.component';
import { BulkPaymentHistoryListingComponent } from './widgets/bulk-payment-history-listing/bulk-payment-history-listing.component';
import { CardFreezeToggleSwitchComponent } from './widgets/card-management-v2/card-freeze-toggle-switch/card-freeze-toggle-switch.component';
import { CardPINWidgetComponent } from './widgets/card-management-v2/card-pin-widget/card-pin-widget.component';
import { CardSensitiveDetailsComponent } from './widgets/card-management-v2/card-sensitive-details/card-sensitive-details.component';
import { CardsWidgetComponent } from './widgets/card-management-v2/cards-widget/cards-widget.component';
import { ClientAccountsListingWidgetComponent } from './widgets/client-accounts-listing-widget/client-accounts-listing-widget.component';
import { ConditionalWidgetComponent } from './widgets/conditional-widget/conditional-widget.component';
import { CopyShareContentWidgetComponent } from './widgets/copy-share-content-widget/copy-share-content-widget.component';
import { EntityMandatoryCTAButtonWidgetComponent } from './widgets/cta-buttons/entity-mandatory-cta-button-widget/entity-mandatory-cta-button-widget.component';
import { EditBeneficiaryComponent } from './widgets/edit-beneficiary/edit-beneficiary.component';
import { FeeClientListingWidgetComponent } from './widgets/fee-client-listing-widget/fee-client-listing-widget.component';
import { FeeConfigurationWidgetComponent } from './widgets/fee-configuration-widget/fee-configuration-widget.component';
import { FeeGroupListingWidgetComponent } from './widgets/fee-group-listing-widget/fee-group-listing-widget.component';
import { InternationalBeneficiaryCredentialsWidgetComponent } from './widgets/international-beneficiary-credentials-widget/international-beneficiary-credentials-widget.component';
import { InternationalBeneficiaryCredentialsComponent } from './widgets/international-beneficiary-credentials/international-beneficiary-credentials.component';
import { InternationalCurrencyCountryWidgetComponent } from './widgets/international-currency-country-widget/international-currency-country-widget.component';
import { ManageUsersAssignPersonaWidgetsComponent } from './widgets/manage-users-assign-persona-widgets/manage-users-assign-persona-widgets.component';
import { ManageUsersStep01WidgetComponent } from './widgets/manage-users-step-01-widget/manage-users-step-01-widget.component';
import { ManageUsersStep02WidgetComponent } from './widgets/manage-users-step-02-widget/manage-users-step-02-widget.component';
import { ManageUsersStep03WidgetComponent } from './widgets/manage-users-step-03-widget/manage-users-step-03-widget.component';
import { OnboardingNextBackButtonComponent } from './widgets/onboarding-next-back-button/onboarding-next-back-button.component';
import { OtpVerificationApproverPaymentsComponent } from './widgets/otp-verification-approver-payments/otp-verification-approver-payments.component';
import { OtpVerificationBulkPaymentsComponent } from './widgets/otp-verification-bulk-payments/otp-verification-bulk-payments.component';
import { OtpVerificationInternationalPaymentsComponent } from './widgets/otp-verification-international-payments/otp-verification-international-payments.component';
import { OtpVerificationPaymentApprovalComponent } from './widgets/otp-verification-payment-approval/otp-verification-payment-approval.component';
import { OtpVerificationWidgetComponent } from './widgets/otp-verification-widget/otp-verification-widget.component';
import { PasswordChangeComponent } from './widgets/password-change/password-change.component';
import { PasswordEnterEmailComponent } from './widgets/password-enter-email/password-enter-email-configuration.component';
import { PasswordForgotComponent } from './widgets/password-forgot/password-forgot.component';
import { PaymentReasonWidgetComponent } from './widgets/payment-reason-widget/payment-reason-widget.component';
import { PaymentRequestPendingApprovalWidgetComponent } from './widgets/payment-request-pending-approval-widget/payment-request-pending-approval-widget.component';
import { ProfilePictureDisplayComponent } from './widgets/profile-picture-display/profile-picture-display.component';
import { ProfilePictureUploadWidgetComponent } from './widgets/profile-picture-upload-widget/profile-picture-upload-widget.component';
import { RangeCalendarWidgetComponent } from './widgets/profile-widgets/range-calendar-widget/range-calendar-widget.component';
import { ResendLinksWidgetComponent } from './widgets/resend-links-widget/resend-links-widget.component';
import { DocumentUploadPreviewComponent } from './widgets/revenir/document-upload-preview/document-upload-preview.component';
import { EuCountryDropdownComponent } from './widgets/revenir/eu-country-dropdown/eu-country-dropdown.component';
import { ImagePreviewComponent } from './widgets/revenir/image-preview/image-preview.component';
import { PDFDocumentPreviewComponent } from './widgets/revenir/pdf-document-preview/pdf-document-preview.component';
import { RevenirArchivedTripsComponent } from './widgets/revenir/revenir-archived-trips/revenir-archived-trips.component';
import { RevenirBarcodeImageViewerComponent } from './widgets/revenir/revenir-barcode-image-viewer/revenir-barcode-image-viewer.component';
import { RevenirTransactionDocumentComponent } from './widgets/revenir/revenir-transaction-document/revenir-transaction-document.component';
import { RevenirTransactionItemListComponent } from './widgets/revenir/revenir-transaction-item-list/revenir-transaction-item-list.component';
import { RevenirTripReclaimStatusComponent } from './widgets/revenir/revenir-trip-reclaim-status/revenir-trip-reclaim-status.component';
import { ReviewUploadBoardingPassComponent } from './widgets/revenir/review-upload-boarding-pass/review-upload-boarding-pass.component';
import { TransactionListComponent } from './widgets/revenir/transaction-list/transaction-list.component';
import { UploadBoardingPassComponent } from './widgets/revenir/upload-boarding-pass/upload-boarding-pass.component';
import { UploadPasssportComponent } from './widgets/revenir/upload-passport/upload-passport.component';
import { TextComponent } from './widgets/text-widget/text-component/text.component';
import { TripInformationExitWidgetComponent } from './widgets/trip-information-exit-widget/trip-information-exit-widget.component';
import { UserNameWidgetComponent } from './widgets/user-name-widget/user-name-widget.component';
import { ViewUsersAssignPersonaWidgetsComponent } from './widgets/view-users-assign-persona-widgets/view-users-assign-persona-widgets.component';

@NgModule({
  declarations: [
    ConfirmationWidgetComponent,
    AccountsContextDropdownWidgetDirective,
    DynamicStyleDirective,
    AccountBalanceWidgetComponent,
    CardManagementWidgetComponent,
    RecentTransactionHistoryWidgetComponent,
    NameComponent,
    BirthdayComponent,
    PhoneNumberComponent,
    EmailComponent,
    AddressComponent,
    QuestionWidgetComponent,
    CompanyHouseCheckComponent,
    OnboardingErrorPopupComponent,
    OnboardingBackPopupComponent,
    QuestionWidgetComponent,
    MotVerificationComponent,
    PasswordComponent,
    PasswordChangeComponent,
    TripInformationExitWidgetComponent,
    PasswordForgotComponent,
    PasswordEnterEmailComponent,
    CarouselWidgetComponent,
    ImageWidgetComponent,
    ProgressbarWidgetComponent,
    LineWidgetComponent,
    TextWidgetComponent,
    TextComponent,
    H1WidgetComponent,
    IFrameWidgetComponent,
    CustomFieldComponent,
    SafeHtmlPipe,
    CustomCurrencyPipe,
    FirstLetterPipe,
    CachedSrcDirective,
    UserPropertiesTableComponent,
    FreezeCardWidgetComponent,
    ChangePinWidgetComponent,
    LostStolenWidgetComponent,
    ReplaceCardWidgetComponent,
    ChangePinBottomPopupComponent,
    ReplaceCardBottomPopupComponent,
    LostStolenBottomPopupComponent,
    AddCardBottomPopupComponent,
    TrackCardDeliveryStatusWidgetComponent,
    PasswordStrengthComponent,
    ChartComponent,
    TextItemWidgetComponent,
    QuickLinksWidgetComponent,
    ResendLinksWidgetComponent,
    VirtualCardOptionsComponent,
    CardActivationComponent,
    ButtonWidgetComponent,
    VerificationPopupComponent,
    VerificationWidgetComponent,
    SumsubWidgetComponent,
    KycProviderComponent,
    SimpleBalanceWidgetComponent,
    ActivateCardSectionComponent,
    ImportUserComponent,
    UserDetailComponent,
    AccountListComponent,
    UserContactDetailComponent,
    UserAccountDetailComponent,
    PasswordSettingComponent,
    UserAddressComponent,
    BeneficiaryCarouselWidgetComponent,
    MonthlyIncomeComponent,
    MonthlyExpenditureComponent,
    SimpleBalanceComponent,
    AdapterCardComponent,
    VirtualCardComponent,
    MeaWalletCardComponent,
    IFCardComponent,
    MultiRoleWidgetComponent,
    QuickLinksComponent,
    PaymentButtonComponent,
    PopupSelectorComponent,
    OnboardingFormElementComponent,
    MonthlyBalanceComponent,
    BalanceChartComponent,
    FreezeCardComponent,
    ViewPinComponent,
    ReplaceCardComponent,
    ChangePinComponent,
    LostStolenComponent,
    BeneficiaryCarouselComponent,
    ConfirmationPopupComponent,
    TopPageNavigatorComponent,
    BeneficiaryCarouselCardComponent,
    AmountSummaryComponent,
    CreditDebitAmountSummaryComponent,
    FrozenStripComponent,
    ActivateCardStripComponent,
    OrderStatusComponent,
    MenuWidgetComponent,
    TransactionHistoryComponent,
    CardsCarouselComponent,
    AlertHandlerComponent,
    EmailVerificationComponent,
    FinxCircleButtonComponent,
    LabelInputComponent,
    BackButtonComponent,
    ForwardButtonComponent,
    LabelInputComponent,
    AddNewCardComponent,
    ConfirmationDialogComponent,
    AddBeneficiaryComponent,
    AccountSelectionComponent,
    CurrencyInputFieldWidgetComponent,
    PaymentRefWidgetComponent,
    MandatoryCTAButtonWidgetComponent,
    EntityMandatoryCTAButtonWidgetComponent,
    LogoutWidgetComponent,
    LogoutComponent,
    SwitchNavigatorQuickLinkWidgetComponent,
    SwitchNavigatorQuickLinkComponent,
    BeneficiarySelectionWidgetComponent,
    BeneficiarySelectionComponent,
    AddBeneficiaryUkfpComponent,
    IntlBeneficiaryAddressComponent,
    IntlBeneficiaryAddedComponent,
    MultiIconQuickLinkWidgetComponent,
    ConditionalWidgetComponent,
    MultiIconQuickLinkComponent,
    ConfirmBeneficiaryDetailsComponent,
    LottieAnimationWidgetComponent,
    TripListingComponent,
    DesktopTripListingComponent,
    DesktopEvenLinkedListingComponent,
    AddFacilityWidgetComponent,
    LinkFacilityWidgetComponent,
    LinkTripWidgetComponent,
    TripInformationComponent,
    ImageDropdownComponent,
    TripSelectorComponent,
    ProfilePictureWidgetComponent,
    BiometricsAuthenticationWidgetComponent,
    NotificationWidgetComponent,
    ToggleSwitchComponent,
    AccountsListingWidgetComponent,
    AccountsListingComponent,
    AccountsContextDropdownWidgetComponent,
    NotificationListComponent,
    ExchangeCurrencyWidgetComponent,
    AddCurrencyAccountWidgetComponent,
    CurrencyListingComponent,
    ExchangeQuoteWidgetComponent,
    ExchangeQuoteSummaryComponent,
    InternationalCurrencyCountryComponent,
    InternationalCurrencyCountryWidgetComponent,
    InternationalBeneficiaryCredentialsWidgetComponent,
    InternationalBeneficiaryCredentialsComponent,
    UploadFileComponent,
    UploadFileWithPopupComponent,
    CompanyNameWidgetComponent,
    RangeCalendarWidgetComponent,
    EditAddressComponent,
    OtpVerificationWidgetComponent,
    OtpVerificationComponent,
    OtpVerificationInternationalPaymentsComponent,
    BaseOtpVerificationComponent,
    CtaButtonWidgetComponent,
    DownloadWidgetComponent,
    PaymentReasonWidgetComponent,
    DesktopUserListingComponent,
    DesktopManageUserListingComponent,
    DesktopEntityListingComponent,
    AssignPersonaRoleWidgetComponent,
    AddEmployeeStep1WidgetsComponent,
    AddEmployeeStep2WidgetsComponent,
    ManageUsersStep01WidgetComponent,
    UserNameWidgetComponent,
    GuestDetailsTextWidgetComponent,
    ManageUsersStep02WidgetComponent,
    ManageUsersStep03WidgetComponent,
    ManageUsersAssignPersonaWidgetsComponent,
    ViewUsersAssignPersonaWidgetsComponent,
    ProfilePictureUploadWidgetComponent,
    ProfilePictureDisplayComponent,
    EditBeneficiaryInternationalCurrencyCountryWidgetComponent,
    EditBeneficiaryIntlBeneficiaryAddressComponent,
    EditBeneficiaryInternationalBeneficiaryCredentialsWidgetComponent,
    EditBeneficiaryComponent,
    DesktopGuestUserListingComponent,
    DesktopEmployeeUserListingComponent,
    BulkPaymentAccountsListingComponent,
    OtpVerificationApproverPaymentsComponent,
    OtpVerificationPaymentApprovalComponent,
    DesktopBulkPaymentListingComponent,
    BulkPaymentHistoryListingComponent,
    OtpVerificationBulkPaymentsComponent,
    FinxFormActionButtonComponent,
    OnboardingNextBackButtonComponent,
    DesktopListingComponent,
    AddEditFeeComponent,
    AddEditFeeWidgetComponent,
    EuCountryDropdownComponent,
    ImagePreviewComponent,
    PDFDocumentPreviewComponent,
    RevenirTransactionDocumentComponent,
    CurrencyFeeConfigurationComponent,
    FeeClientListingWidgetComponent,
    AddFeeGroupWidgetComponent,
    AddFeeGroupComponent,
    TransactionListComponent,
    RevenirTransactionItemListComponent,
    RevenirTripReclaimStatusComponent,
    RevenirArchivedTripsComponent,
    FeeGroupListingWidgetComponent,
    ClientAccountsListingWidgetComponent,
    AssignGroupWidgetComponent,
    AssignGroupComponent,
    FeeConfigurationWidgetComponent,
    CopyShareComponent,
    CopyShareContentWidgetComponent,
    SectionWidgetComponent,
    TwoColumnWidgetComponent,
    TabWidgetComponent,
    CardComponent,
    CardsWidgetComponent,
    CardFreezeToggleSwitchComponent,
    DocumentUploadPreviewComponent,
    CardSensitiveDetailsComponent,
    RevenirBarcodeImageViewerComponent,
    UploadBoardingPassComponent,
    ReviewUploadBoardingPassComponent,
    UploadPasssportComponent,
    PaymentRequestPendingApprovalWidgetComponent,
    LazyLoadDirective,
    SecondaryIdentifierComponent,
    SpacerWidgetComponent,
    AppPinConfirmationComponent,
    PinBoxesComponent,
    PinPadComponent,
    CardNameWidgetComponent,
    CardPINWidgetComponent,
    AddToGooglePayWidgetComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    UiComponentsModule,
    ReactiveFormsModule.withConfig({
      warnOnNgModelWithFormControl: 'never',
    }),
    TabViewModule,
    FormsModule,
    AutoCompleteModule,
    OverlayPanelModule,
    NgxSmartModalModule,
    DialogModule,
    DropdownModule,
    DragScrollModule,
    NgChartsModule,
    ToastModule,
    LayoutsModule,
    ConfirmDialogModule,
    PaginatorModule,
    SelectButtonModule,
    ProgressBarModule,
    InputNumberModule,

    HlmTabsComponent,
    HlmTabsListComponent,
    HlmTabsTriggerDirective,
    HlmTabsContentDirective,

    HlmCardContentDirective,
    HlmCardDescriptionDirective,
    HlmCardDirective,
    HlmCardFooterDirective,
    HlmCardHeaderDirective,
    HlmCardTitleDirective,

    HlmLabelDirective,
    HlmInputDirective,
    HlmButtonDirective,

    LottieAnimationComponent,
    NgxJdenticonModule,
    OverlayModule,
    NgOtpInputModule,
    PdfViewerModule,
    SkeletonModule,
  ],
  exports: [
    ConfirmationWidgetComponent,
    AccountBalanceWidgetComponent,
    CardManagementWidgetComponent,
    RecentTransactionHistoryWidgetComponent,
    NameComponent,
    BirthdayComponent,
    PhoneNumberComponent,
    AddressComponent,
    QuestionWidgetComponent,
    OnboardingErrorPopupComponent,
    OnboardingBackPopupComponent,
    QuestionWidgetComponent,
    MotVerificationComponent,
    PasswordComponent,
    PasswordChangeComponent,
    TripInformationExitWidgetComponent,
    PasswordForgotComponent,
    PasswordEnterEmailComponent,
    CarouselWidgetComponent,
    ImageWidgetComponent,
    ProgressbarWidgetComponent,
    LineWidgetComponent,
    TextWidgetComponent,
    TextComponent,
    H1WidgetComponent,
    IFrameWidgetComponent,
    CustomFieldComponent,
    UserPropertiesTableComponent,
    FreezeCardWidgetComponent,
    ChangePinWidgetComponent,
    LostStolenWidgetComponent,
    ReplaceCardWidgetComponent,
    ChangePinBottomPopupComponent,
    ReplaceCardBottomPopupComponent,
    LostStolenBottomPopupComponent,
    AddCardBottomPopupComponent,
    TrackCardDeliveryStatusWidgetComponent,
    PasswordStrengthComponent,
    ChartComponent,
    TextItemWidgetComponent,
    QuickLinksWidgetComponent,
    ResendLinksWidgetComponent,
    VirtualCardOptionsComponent,
    CardActivationComponent,
    VerificationPopupComponent,
    SumsubWidgetComponent,
    KycProviderComponent,
    SimpleBalanceWidgetComponent,
    VerificationWidgetComponent,
    ActivateCardSectionComponent,
    ImportUserComponent,
    UserDetailComponent,
    AccountListComponent,
    UserContactDetailComponent,
    UserAccountDetailComponent,
    PasswordSettingComponent,
    UserAddressComponent,
    BeneficiaryCarouselWidgetComponent,
    MonthlyIncomeComponent,
    MonthlyExpenditureComponent,
    SimpleBalanceComponent,
    AdapterCardComponent,
    VirtualCardComponent,
    MeaWalletCardComponent,
    IFCardComponent,
    MultiRoleWidgetComponent,
    QuickLinksComponent,
    PaymentButtonComponent,
    PopupSelectorComponent,
    OnboardingFormElementComponent,
    MonthlyBalanceComponent,
    BalanceChartComponent,
    FreezeCardComponent,
    ViewPinComponent,
    ReplaceCardComponent,
    ChangePinComponent,
    LostStolenComponent,
    BeneficiaryCarouselComponent,
    ConfirmationPopupComponent,
    TopPageNavigatorComponent,
    BeneficiaryCarouselCardComponent,
    AmountSummaryComponent,
    CreditDebitAmountSummaryComponent,
    FrozenStripComponent,
    ActivateCardStripComponent,
    OrderStatusComponent,
    MenuWidgetComponent,
    TransactionHistoryComponent,
    CardsCarouselComponent,
    AlertHandlerComponent,
    EmailVerificationComponent,
    FinxCircleButtonComponent,
    BackButtonComponent,
    ForwardButtonComponent,
    LabelInputComponent,
    AddNewCardComponent,
    ConfirmationDialogComponent,
    AddBeneficiaryComponent,
    IntlBeneficiaryAddressComponent,
    IntlBeneficiaryAddedComponent,
    AccountSelectionComponent,
    CurrencyInputFieldWidgetComponent,
    PaymentRefWidgetComponent,
    MandatoryCTAButtonWidgetComponent,
    EntityMandatoryCTAButtonWidgetComponent,
    LogoutWidgetComponent,
    LogoutComponent,
    SafeHtmlPipe,
    SwitchNavigatorQuickLinkWidgetComponent,
    SwitchNavigatorQuickLinkComponent,
    BeneficiarySelectionWidgetComponent,
    BeneficiarySelectionComponent,
    MultiIconQuickLinkWidgetComponent,
    ConditionalWidgetComponent,
    MultiIconQuickLinkComponent,
    ConfirmBeneficiaryDetailsComponent,
    LottieAnimationWidgetComponent,
    LottieAnimationComponent,
    TripListingComponent,
    DesktopTripListingComponent,
    DesktopEvenLinkedListingComponent,
    AddFacilityWidgetComponent,
    LinkFacilityWidgetComponent,
    LinkTripWidgetComponent,
    TripInformationComponent,
    ImageDropdownComponent,
    TripSelectorComponent,
    ProfilePictureWidgetComponent,
    BiometricsAuthenticationWidgetComponent,
    NotificationWidgetComponent,
    ToggleSwitchComponent,
    AccountsListingWidgetComponent,
    AccountsListingComponent,
    AccountsContextDropdownWidgetComponent,
    NotificationListComponent,
    ExchangeCurrencyWidgetComponent,
    AddCurrencyAccountWidgetComponent,
    CurrencyListingComponent,
    InternationalCurrencyCountryComponent,
    InternationalCurrencyCountryWidgetComponent,
    UploadFileComponent,
    UploadFileWithPopupComponent,
    CompanyNameWidgetComponent,
    RangeCalendarWidgetComponent,
    EditAddressComponent,
    OtpVerificationWidgetComponent,
    OtpVerificationComponent,
    OtpVerificationInternationalPaymentsComponent,
    BaseOtpVerificationComponent,
    CtaButtonWidgetComponent,
    DownloadWidgetComponent,
    DesktopUserListingComponent,
    DesktopManageUserListingComponent,
    DesktopEntityListingComponent,
    AssignPersonaRoleWidgetComponent,
    AddEmployeeStep1WidgetsComponent,
    AddEmployeeStep2WidgetsComponent,
    ManageUsersStep01WidgetComponent,
    UserNameWidgetComponent,
    GuestDetailsTextWidgetComponent,
    ManageUsersStep02WidgetComponent,
    ManageUsersStep03WidgetComponent,
    ManageUsersAssignPersonaWidgetsComponent,
    ViewUsersAssignPersonaWidgetsComponent,
    ProfilePictureUploadWidgetComponent,
    ProfilePictureDisplayComponent,
    EditBeneficiaryInternationalCurrencyCountryWidgetComponent,
    EditBeneficiaryIntlBeneficiaryAddressComponent,
    EditBeneficiaryInternationalBeneficiaryCredentialsWidgetComponent,
    EditBeneficiaryComponent,
    DesktopGuestUserListingComponent,
    DesktopEmployeeUserListingComponent,
    BulkPaymentAccountsListingComponent,
    OtpVerificationApproverPaymentsComponent,
    OtpVerificationPaymentApprovalComponent,
    DesktopBulkPaymentListingComponent,
    BulkPaymentHistoryListingComponent,
    OtpVerificationBulkPaymentsComponent,
    FinxFormActionButtonComponent,
    OnboardingNextBackButtonComponent,
    DesktopListingComponent,
    AddEditFeeComponent,
    AddEditFeeWidgetComponent,
    EuCountryDropdownComponent,
    ImagePreviewComponent,
    PDFDocumentPreviewComponent,
    RevenirTransactionDocumentComponent,
    CurrencyFeeConfigurationComponent,
    FeeClientListingWidgetComponent,
    AddFeeGroupWidgetComponent,
    AddFeeGroupComponent,
    TransactionListComponent,
    RevenirTransactionItemListComponent,
    RevenirTripReclaimStatusComponent,
    RevenirArchivedTripsComponent,
    FeeGroupListingWidgetComponent,
    ClientAccountsListingWidgetComponent,
    AssignGroupWidgetComponent,
    AssignGroupComponent,
    FeeConfigurationWidgetComponent,
    CopyShareComponent,
    CopyShareContentWidgetComponent,
    SectionWidgetComponent,
    TwoColumnWidgetComponent,
    TabWidgetComponent,
    CardComponent,
    CardsWidgetComponent,
    DocumentUploadPreviewComponent,
    UploadBoardingPassComponent,
    ReviewUploadBoardingPassComponent,
    UploadPasssportComponent,
    PaymentRequestPendingApprovalWidgetComponent,
    LazyLoadDirective,
    SecondaryIdentifierComponent,
    SpacerWidgetComponent,
    CardNameWidgetComponent,
    CardPINWidgetComponent,
    AddToGooglePayWidgetComponent,
  ],
  providers: [
    NgxSmartModalService,
    DatePipe,
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    MessageService,
    // Used to resolve: https://github.com/angular/components/issues/18890
    { provide: ViewportRuler, useClass: CustomViewportRuler },
    provideLottieOptions({
      player: () => player,
    }),
  ],
})
export class SharedModule {}
