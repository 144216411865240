import { HttpContextToken } from '@angular/common/http';

export const IS_EXTERNAL_KEYCLOAK: HttpContextToken<boolean> = new HttpContextToken<boolean>(() => false);

export const FILE_ENDPOINTS: string[] = [
  'file/file-upload',
  '/receipt',
  '/document',
  '/ticket',
  '/passports',
] as const;
